import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';

import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { Glider } from '@app/core/interfaces/glider.interace';

@Component({
  selector: 'app-dialog-glider-information',
  templateUrl: './dialog-glider-information.component.html',
  styleUrls: ['./dialog-glider-information.component.css'],
})
export class DialogGliderInfoComponent implements OnInit, OnDestroy {
  form!: FormGroup;
  subject = new Subject<any>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Glider,
    public dialogRef: MatDialogRef<DialogGliderInfoComponent>,
    public datepipe: DatePipe
  ) {
    this.form = new FormGroup({
      id: new FormControl(this.data.id),
      name: new FormControl(this.data.name),
      ip: new FormControl(this.data.vpnIp),
      vpn: new FormControl(this.data.vpnNetworkId),
      glider_status: new FormControl(this.data.status.name),
    });
  }

  ngOnInit(): void {}

  get formDialog(): any {
    return this.form.controls;
  }

  ngOnDestroy(): void {
    this.subject?.complete();
    this.subject.unsubscribe();
  }
}
