import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';

import { Ride } from '@app/core/interfaces/ride.interface';
import { RideService } from '@services/ride/ride.service';
import { NotificationsService } from '@shared/services/notifications.service';

@Component({
  selector: 'app-dialog-ride-information',
  templateUrl: './dialog-ride-information.component.html',
  styleUrls: ['./dialog-ride-information.component.css'],
})
export class DialogRideInfoComponent implements OnInit, OnDestroy {
  form!: FormGroup;
  subject = new Subject<any>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Ride,
    public dialogRef: MatDialogRef<DialogRideInfoComponent>,
    private datepipe: DatePipe,
    private rideService: RideService,
    private notificationsService: NotificationsService
  ) {
    this.form = new FormGroup({
      id: new FormControl({ value: data.id, disabled: true }),
      operator: new FormControl({ value: data.operator_id, disabled: true }),
      category: new FormControl({ value: data.category || '', disabled: true }),
      gsc_id: new FormControl({ value: data.gsc_id || '', disabled: true }),
      scheduled: new FormControl({ value: data.scheduled || '', disabled: true }),
      location_departure: new FormControl({ value: data.from_location, disabled: true }),
      location_arrival: new FormControl({ value: data.to_location, disabled: true }),
      time_departure: new FormControl({
        value: this.datepipe.transform(data.departure_time, 'M/d/yy, h:mm a'),
        disabled: true,
      }),
      time_arrival: new FormControl({
        value: this.datepipe.transform(data.arrival_time, 'M/d/yy, h:mm a'),
        disabled: true,
      }),
      status: new FormControl({ value: data.ride_status_id, disabled: true }),
      glider_id: new FormControl({ value: data.glider_name, disabled: true }),
      has_package: new FormControl(data.has_package ?? false),
      package_description: new FormControl(data.package_description ?? ''),
    });
  }

  ngOnInit(): void {}

  onSave(): void {
    const payload = {
      has_package: this.form.value.has_package,
      package_description: this.form.value.package_description,
    };

    this.rideService.updateRide(this.data.id, payload).subscribe({
      next: () => {
        this.notificationsService.openSnackBar('Ride updated successfully', 'Ok');
        this.dialogRef.close(true);
      },
      error: (err) => {
        console.error('Error updating ride', err);
        this.notificationsService.openSnackBar('Error updating ride', 'Ok');
      },
    });
  }

  get formDialog(): any {
    return this.form.controls;
  }

  ngOnDestroy(): void {
    this.subject.complete();
    this.subject.unsubscribe();
  }
}
