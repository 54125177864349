<mat-dialog-content class="p-3">
  <h2 mat-dialog-title class="mb-3">
    Ride #{{ form.get('id')?.value }}
  </h2>

  <form [formGroup]="form">
    <h3 class="section-title">General Info</h3>
    <div class="row">
      <div class="col-md-4 mb-3">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Operator</mat-label>
          <input matInput formControlName="operator" [disabled]="true"/>
        </mat-form-field>
      </div>
      <div class="col-md-4 mb-3">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Category</mat-label>
          <input matInput formControlName="category" [disabled]="true"/>
        </mat-form-field>
      </div>
      <div class="col-md-4 mb-3">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>GSC</mat-label>
          <input matInput formControlName="gsc_id" [disabled]="true"/>
        </mat-form-field>
      </div>
    </div>

    <h3 class="section-title">Locations & Times</h3>
    <div class="row">
      <div class="col-md-6 mb-3">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Location departure</mat-label>
          <input matInput formControlName="location_departure" [disabled]="true" />
        </mat-form-field>
      </div>
      <div class="col-md-6 mb-3">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Location arrival</mat-label>
          <input matInput formControlName="location_arrival" [disabled]="true" />
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 mb-3">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Time departure</mat-label>
          <input matInput formControlName="time_departure" [disabled]="true" />
        </mat-form-field>
      </div>
      <div class="col-md-6 mb-3">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Time arrival</mat-label>
          <input matInput formControlName="time_arrival" [disabled]="true" />
        </mat-form-field>
      </div>
    </div>

    <h3 class="section-title">Status & Scheduling</h3>
    <div class="row">
      <div class="col-md-4 mb-3">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Status</mat-label>
          <input matInput formControlName="status" [disabled]="true" />
        </mat-form-field>
      </div>
      <div class="col-md-4 mb-3">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Glider</mat-label>
          <input matInput formControlName="glider_id" [disabled]="true" />
        </mat-form-field>
      </div>
      <div class="col-md-4 mb-3">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Scheduled</mat-label>
          <input matInput formControlName="scheduled" [disabled]="true"/>
        </mat-form-field>
      </div>
    </div>

    <h3 class="section-title">Package Info</h3>
    <div class="row align-items-center">
      <div class="col-md-4 mb-3">
        <mat-slide-toggle color="primary" formControlName="has_package">
          Has package?
        </mat-slide-toggle>
      </div>
      <div class="col-md-8 mb-3">
        <mat-form-field appearance="fill" class="w-100">
          <mat-label>Package description</mat-label>
          <input matInput formControlName="package_description" />
        </mat-form-field>
      </div>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions align="end" class="p-3">
  <button mat-button (click)="dialogRef.close()">Cancel</button>
  <button mat-raised-button color="primary" (click)="onSave()">Save</button>
</mat-dialog-actions>
