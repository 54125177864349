import {HttpClient, HttpParams, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Response} from '@app/core/interfaces/responses/response.interface';
import {Ride, RideExportFilters, RideStatus} from '@app/core/interfaces/ride.interface';
import {typeAction} from '@app/shared/utils/enum';
import {environment} from '@src/environments/environment';
import {KeycloakService} from 'keycloak-angular';
import {Observable, Subject} from 'rxjs';
import {from} from 'rxjs';
import {switchMap} from 'rxjs/operators';

@Injectable()
export class RideService {
  private apiUrl = environment.urlMsRides;
  // private apiStatusUrl = environment.urlMsRidesStatus;
  private subjectRides = new Subject<Ride[]>();
  public action: typeAction | undefined;

  constructor(private http: HttpClient, private keycloakService: KeycloakService) {
  }

  getRideList(): Observable<Response<Ride[]>> {
    return from(this.keycloakService.getToken()).pipe(
      switchMap((token) => {
        const headers = new HttpHeaders({
          Authorization: `Bearer ${token}`,
        });
        // TODO: Remove limit and skip params
        return this.http.get<Response<Ride[]>>(`${this.apiUrl}/rides`, {headers, params: {'limit': 5000, 'skip': 0}});
      })
    );

  }

  getRideCSV(params: RideExportFilters): Observable<any> {
    let url_params = new HttpParams();

    if (params.include_package && !params.include_no_package) {
      url_params = url_params.append('ride[has_package]', 'true');
    }
    if (!params.include_package && params.include_no_package) {
      url_params = url_params.append('ride[has_package]', 'false');
    }


    if (params.start_date) {
      url_params = url_params.append('ride[start_date]', params.start_date);
    }

    if (params.end_date) {
      url_params = url_params.append('ride[end_date]', params.end_date);
    }

    if (params.glider) {
      url_params = url_params.append('ride[glider_id]', params.glider.id);
    }

    if (params.start_location) {
      url_params = url_params.append('ride[from_location_id]', params.start_location.id);
    }

    if (params.destination_location) {
      url_params = url_params.append('ride[to_location_id]', params.destination_location.id);
    }

    return this.http.get(`${this.apiUrl}.csv`, {responseType: 'text', params: url_params});
  }

  getRidesStatusList(): Observable<Response<RideStatus[]>> {
    return this.http.get<Response<RideStatus[]>>(`${this.apiUrl}/ride-statuses`);
  }

  updateRide(rideId: number, ride: any): Observable<Response<Ride>> {
    return this.http.patch<Response<Ride>>(
      `${this.apiUrl}/rides/${rideId}`,
      ride
    );
  }

  notifyDataSubject(ride: Ride[], action?: typeAction): void {
    this.action = action;
    this.subjectRides.next(ride);
  }

  listenDataSubjectRide(): Observable<Ride[]> {
    return this.subjectRides.asObservable();
  }
}
